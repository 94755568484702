import React from "react"

// const FacebookPluggin = () => {
//   return (
//     <>
//       <div id="fb-root"></div>
//       <div id="fb-customer-chat" class="fb-customerchat"></div>
//       <div 
//         class="zalo-chat-widget" 
//         data-oaid="3473601869890654178" 
//         data-welcome-message="Rất vui khi được hỗ trợ bạn!" 
//         data-autopopup="0" 
//         data-width="" 
//         data-height=""></div>
//     </>
//   )
// }

const FacebookPluggin = () => {
  return (
    <>
      <div 
        class="zalo-chat-widget" 
        data-oaid="3473601869890654178" 
        data-welcome-message="Rất vui khi được hỗ trợ bạn!" 
        data-autopopup="0" 
        data-width="" 
        data-height=""></div>
    </>
  )
}

export default FacebookPluggin
