import React from "react"

const FloatingButton = () => {
  return (
    <div>
      <div className="floating_button" id="floating_button"></div>
    </div>
  )
}

export default FloatingButton
